import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { Login, LoginService } from "../services/login.service";

// Alterar posteriormente
// const APIURL = "https://tato-tst.crescer.net";
//const APIURL = "http://10.0.77.244:8041";
const APIURL = "https://www.rio-aguas-api.protix.com.br";

export function getURL() {
  return APIURL;
}
@Injectable({
  providedIn: "root",
})
export class ApiService {
  public loginSessao: Login = {} as Login;

  constructor(private http: HttpClient, private loginService: LoginService) {
    this.loginService.getLogin().then((login: Login) => {
      this.loginSessao = login;
    });
  }

  httpOptions = {
    header: new HttpHeaders({
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT",
      enctype: "multipart/form-data; boundary=WebAppBoundary",
      "Content-Type": "multipart/form-data; boundary=WebAppBoundary",
      Accept: "application/json",
    }),
    params: {},
  };

  login(params) {
    this.httpOptions.params = params;
    this.httpOptions.header.append("Access-Control-Allow-Credentials", "true");

    return this.http.request("POST", APIURL + "/login", this.httpOptions);
  }

  resetSenha(params) {
    this.httpOptions.params = params;
    this.httpOptions.header.append("Access-Control-Allow-Credentials", "true");

    return this.http.request("POST", APIURL + "/reset_senha", this.httpOptions);
  }

  sendData(params, route): Observable<any> {
    const form_data = new FormData();
    let params_new = params;
    params_new = this.setParamsSessao(params_new, null);
    form_data.append("params", JSON.stringify(params_new));
    this.httpOptions.header.append("Access-Control-Allow-Credentials", "true");
    this.httpOptions["responseType"] = "";
    return this.http.post(APIURL + "/" + route, form_data, this.httpOptions);
  }

  sendData2(params, route): Observable<any> {
    const form_data = new FormData();
    let params_new = params;
    //params_new = this.setParamsSessao(params_new, null);
    form_data.append("params", JSON.stringify(params_new));
    this.httpOptions.header.append("Access-Control-Allow-Credentials", "true");
    this.httpOptions["responseType"] = "";
    return this.http.post(APIURL + "/" + route, form_data, this.httpOptions);
  }

  getFile(params, route): Observable<any> {
    const form_data = new FormData();
    let params_new = params;
    params_new = this.setParamsSessao(params_new, null);
    form_data.append("params", JSON.stringify(params_new));
    this.httpOptions["responseType"] = "blob";
    return this.http.post(APIURL + "/" + route, form_data, this.httpOptions);
  }

  sendDataAndFile(params, paramsFile: File[], route): Observable<any> {
    const form_data = new FormData();
    let params_new = params;
    params_new = this.setParamsSessao(params_new, null);
    if (paramsFile != undefined) {
      for (const file of paramsFile) {
        form_data.append('file_anexo[]', file, file.name);
      }
    }
    form_data.append("params", JSON.stringify(params_new));
    this.httpOptions.header.append("Access-Control-Allow-Credentials", "true");
    this.httpOptions["responseType"] = "";
    return this.http.post(APIURL + "/" + route, form_data, this.httpOptions);
  }

  syncData(route, login = null): Observable<any> {
    let params_new = this.setParamsSessao({}, login);
    this.httpOptions.params = params_new;
    this.httpOptions.header.append("Access-Control-Allow-Credentials", "true");
    this.httpOptions["responseType"] = "";
    return this.http.request("POST", APIURL + "/" + route, this.httpOptions);
  }

  private setParamsSessao(params, login) {
    if (this.loginSessao == null) {
      this.loginSessao = login;
    }
    params["token"] = this.loginSessao.token;
    params["token_exp"] = this.loginSessao.token_exp;
    return params;
  }
}
